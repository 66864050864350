/* .vertical-timeline::before {
    background-color: #cfe2ff !important;
} */

.image-center{  
        margin-left: 7px;
        margin-top: 6px;
}

.vertical-timeline-element-icon {
    box-shadow: 0 0 0 4px #f7ede2, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(207, 226, 255, 0.05) !important;
  }
  .jumbotron{
      background-color: #FF6563 !important;
  }