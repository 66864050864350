.fundo-main {
    width: 100%;
    overflow: auto;
    padding: 1%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    background-color: #27a7a6;
    background-size: 400px;
}
.container2{
    background-color: white !important;
    border-color: transparent !important;
    border: 1px solid;
    padding: 10px;
    box-shadow: 5px 10px 18px #888888;
}
.txt-roxo{
    color: #2f2971;
}
.bt-roxo{
    background-color: #2f2971;
    border: none;
    border-radius: 8px;
    transition: 0.4s;
    margin-left: auto;
    margin-right: auto;
    color: white;
    padding: 4px;
    padding-inline: 12px;
}
.link-roxo{
    color: white;
}
.bt-roxo:hover{
    background-color: #27a7a6;
}

.btn-primary {
    color:black !important;
    background-color: #f7ede2 !important;
    border-color: #f7ede2 !important;
    width: 100%;
}

.btn-primary-out {
    color:black !important;
    background-color: white !important;
    border-color: #f7ede2 !important;
    width: 100%;
}

.txt-verde{
    color: #00a7a6 !important;
}

.botao-card{
    background-color: #f7ede2 !important;
    border: none !important;
    border-radius: 20px !important;
    color: black !important;
}
.nolink{
    color: black;
    text-decoration-line: none;
}
.txt-primary{
    color: black;
}
.bg-color-verde{
    background-color: #84a59d;
}