.input-icons i { 
    position: absolute; 
} 

.input-icons { 
    width: 100%; 
    margin-bottom: 10px; 
} 

.icon { 
    padding: 10px; 
    min-width: 40px; 
} 

.input-field { 
    width: 100%; 
    padding: 10px; 
    text-align: center;
}
.btn-login{
    background-color: #2f2971;
    color: #ffffff;
    transition: 0.7s;
}
.btn-login:hover{
    background-color: #27a7a6;
}

.form-login{
    background-color: #ffffff;
    padding: 16px;
    border-radius: 25px;
    height: 300px;
    width: 300px;
}

.txtfalha{
    color: red;
}